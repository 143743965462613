@import './variables';
@import './mixins';

@keyframes dotty {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
}

.loading-dots::after {
    min-width: 10px;
    display: inline-block;
    animation: dotty steps(1,end) 2s infinite;
    content: '';
}

.pen{
    pointer-events: none;
}
.usn {
    user-select: none;
}
.cPointer{
    cursor: pointer!important;
}
.cDefault{
    cursor: default;
}
.cMove{
    cursor: move;
}
.taC { text-align: center; }
.taL { text-align: left; }
.taR { text-align: right; }
.ttu { text-transform: uppercase; }
.fB100 { flex-basis: 100% }

.green { color: $GREEN };
.red { color: $RED };
.microsoft-red {color: $MICROSOFT_RED}
.blue-lt { color: $LT_BLUE }
.purple { color: $GABBIE_PURPLE }
.orange { color: $ORANGE }

.conceal {
    visibility: hidden !important;
    overflow: hidden !important;
    width: 0 !important;
    height: 0 !important;
}

.invisible {
    visibility: hidden !important;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.inline-block {
    display: inline-block;
}

.overVisible {
    overflow: visible;
}

.overHidden {
    overflow: hidden;
}

.overAuto {
    overflow: auto;
}

.flexRow {
    display: flex;
    flex-flow: row nowrap;
}

.flexGrow1 {
    flex-grow: 1;
}

.flex1 {
    display: flex;
    flex: 1;
}

.asC {
    align-self: center !important; 
}

.acC {
    align-content: center !important;
}

.acFS {
    align-content: flex-start !important;
}

.acFE {
    align-content: flex-end !important;
}

.aiC {
    align-items: center;
}

.aiFS {
    align-items: flex-start!important;
}

.aiFE {
    align-items: flex-end!important;
}

.jcC {
    justify-content: center;
}

.jcSA {
    justify-content: space-around!important;
}

.jcFS {
    justify-content: flex-start!important;
}

.jcFE {
    justify-content: flex-end!important;
}

.flexColumn {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.fg1 {
    flex-grow: 1;
}

.fg0 {
    flex-grow: 0;
}

.hAuto {
    height: auto;
}

.wAuto {
    width: auto;
}

.h0 {
    height: 0 !important;
}

.mA {
    margin: auto;
}

.pRel {
    position: relative;
}

.pAbs {
    position: absolute;
}

.uppercase {
    text-transform: uppercase;
}

.no-data {
    @include noDataText;
}

.hide-empty:empty {
    display: none;
}

.bg-transparent {
    background-color: transparent !important;
}

@for $i from 1 through 30 {
    $value: $i * 5;
    .gap#{$value}p {
        gap: $value * 1px;
    }
}

// Font size
@for $i from 1 through 30 {
    $value: $i * 2;
    .fs#{$value}p {
        font-size: $value * 1px !important;
    }
}

// Font weights 
@for $i from 0 through 500 {
    $value: $i + 100;
    .fw#{$value} {
        font-weight: $value;
    }
}

// START Widths
@for $i from 1 through 10 {
    $value: $i * 10;
    .mw#{$value}p {
        max-width: $value * 1%;
    }
}

@for $i from 1 through 1000 {
    $value: $i + 10;
    .w#{$value} {
        width: $value * 1px;
    }
}

@for $i from 0 through 10 {
    $value: $i * 10;
    .w#{$value}p {
        width: $value * 1%;
    }
}
// END Widths

// START Heights
@for $i from 1 through 10 {
    $value: $i * 10;
    .h#{$value}p {
        height: $value * 1%;
    }
}

@for $i from 1 through 10 {
    $value: $i * 10;
    .mh#{$value}p {
        max-height: $value * 1%;
    }
}

@for $i from 1 through 500 {
    $value: $i * 5;
    .h#{$value} {
        height: $value * 1px;
    }
}
// END Heights

// START Padding
.p0 {
    padding: 0!important;
}
@for $i from 1 through 30 {
    $value: $i * 5;
    .p#{$value}px {
        padding: $value * 1px !important;
    }
}
// Padding left
@for $i from 0 through 4 {
    $value: $i * 2;
    .pL#{$value} {
        padding-left: $value * 1px !important;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pL#{$value} {
        padding-left: $value * 1px !important;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pL#{$value}p {
        padding-left: $value * 1% !important;
    }
}

// Padding right
@for $i from 0 through 4 {
    $value: $i * 2;
    .pR#{$value} {
        padding-right: $value * 1px !important;
    }
}

@for $i from 1 through 30 {
    $value: $i * 5;
    .pR#{$value} {
        padding-right: $value * 1px !important;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pR#{$value}p {
        padding-right: $value * 1% !important;
    }
}

// Padding top
@for $i from 0 through 4 {
    $value: $i * 2;
    .pT#{$value} {
        padding-top: $value * 1px !important;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pT#{$value} {
        padding-top: $value * 1px !important;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pT#{$value}p {
        padding-top: $value * 1% !important;;
    }
}

// Padding bottom
@for $i from 0 through 4 {
    $value: $i * 2;
    .pB#{$value} {
        padding-bottom: $value * 1px !important;;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pB#{$value} {
        padding-bottom: $value * 1px !important;;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .pB#{$value}p {
        padding-bottom: $value * 1% !important;;
    }
}
// END Padding

// START Margin
.m0 {
    margin: 0;
}
.mLa {
    margin-left: auto;
}
.mRa {
    margin-right: auto;
}
.mTa {
    margin-top: auto;
}
// Margin left
@for $i from 0 through 4 {
    $value: $i * 2;
    .mL#{$value} {
        margin-left: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mL#{$value} {
        margin-left: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mL#{$value}p {
        margin-left: $value * 1%;
    }
}

// Margin right
@for $i from 0 through 4 {
    $value: $i * 2;
    .mR#{$value} {
        margin-right: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mR#{$value} {
        margin-right: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mR#{$value}p {
        margin-right: $value * 1%;
    }
}

// Margin top
@for $i from 0 through 4 {
    $value: $i * 2;
    .mT#{$value} {
        margin-top: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mT#{$value} {
        margin-top: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mT#{$value}p {
        margin-top: $value * 1%;
    }
}

// Margin bottom
@for $i from 0 through 4 {
    $value: $i * 2;
    .mB#{$value} {
        margin-bottom: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mB#{$value} {
        margin-bottom: $value * 1px;
    }
}

@for $i from 1 through 20 {
    $value: $i * 5;
    .mB#{$value}p {
        margin-bottom: $value * 1%;
    }
}
// END Margin

@for $i from 1 through 10 {
    .clamp-#{$i} {
        @include text-multi-line-ellipsis($i);
    }
}
