////// Colors
$WHITE: #ffffff;
$WHITE_05P: rgba(255, 255, 255, .05);
$WHITE_10P: rgba(255, 255, 255, .10);
$WHITE_15P: rgba(255, 255, 255, .15);
$WHITE_30P: rgba(255, 255, 255, .30);
$WHITE_45P: rgba(255, 255, 255, .45);
$WHITE_65P: rgba(255, 255, 255, .65); // loading bg
$WHITE_80P: rgba(255, 255, 255, .80);
$FAKE_TRANSPARENT: rgb(161, 200, 224); //

$RED: #E02943; // statuses
$DK_RED: #8B0000; // Error background color

$GREEN: #43C286; // statuses
$DK_GREEN: #228B22; // Used for .active button hover (tag filters selected and then hovered)

$ORANGE: #FDAE33; // rules and work form editors

$DK_GREEN: #228B22;
$THROWUP_GREEN: #adcd00;
$GABBIE_GREEN: #2ab530;
$ORANGE: #FFA500;
$GABBIE_ORANGE: #ffa81d;
$BLUE: #345CF6;
$DK_DK_BLUE: #214c66;
$DK_BLUE: #005cb9;
$LT_BLUE: #4197cb;
$LT_BLUE_25P: rgba(65, 151, 203, 0.25);
$LT_BLUE_50P: rgba(65, 151, 203, 0.5);
$GABBIE_PURPLE: #9a36f7;
$BLACK: #000;
$ALMOST_BLACK: #181818;
$BLACK_38P: rgba(0, 0, 0, 0.38);
$BLACK_50P: rgba(0, 0, 0, 0.50);
$GRAY: #7b868c;
$DK_GRAY:  #363636;
$DD_GRAY: #373737;
$DISABLED_GRAY: #3c3c3c;
$DISABLED_GRAY_35P: rgba(60, 60, 60, 0.35);
$DISABLED_GRAY_65P: rgba(60, 60, 60, 0.65);
$MED_GRAY: #EBEFF7;
$LT_GRAY: #F9FBFF;
$GABBIE_GRAY: #676767;
$MED_LT_GRAY: #8b8b8b;
$GRAY_40: rgba(136, 136, 136, 0.4);
$ANOTHER_GRAY1: #676767;
$GRAY_50P: rgba(48,56,75, .5);
$GRAY_5P: rgba(48,56,75, .05);

$INFO: #0f98d0;
$WARNING: #ffab00;
$DANGER: #fc1449;
$LT_DANGER: #fc1449c0;
$MICROSOFT_RED: #f04e1f;

$MODAL_BACKDROP_BLACK: rgba(24, 24, 24, 0.8);

// MORE GRAYS
$GRAY_DK: #262626;
$GRAY_MD: #3c3c3c;
$GRAY_LT: #686868;
$GRAY_XLT: #888888;

// Fonts/Text
$font-weight-500: 500;
$font-weight-400: 400;
$font-weight-300: 300;
$font-weight-200: 200;
$font-weight-100: 100;

$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px; // default
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-26: 26px;
$font-size-30: 30px;
$font-size-32: 32px;
$font-size-40: 40px;
$font-size-60: 60px;

// z-index
$z100: 100;
$z200: 200;
$z300: 300;
$z400: 400;
$z500: 500;
$z600: 600;
$z700: 700;
$z800: 800;
$z900: 900; // modal
$z901: 901; // modal close
$z1100: 1100; // veil backdrops
$z1200: 1200; // loading

// Misc.
$border-radius: 5px;
$disabled-opacity: .65;

// Circle
$border-circle: 50%;

// Component Dimensions
$header-height: 45px;
$navigation-open: 290px;
$navigation-closed: 70px;
$sub-nav-width: 150px;
$map-data-min-width: 425px;
$page-wrapper-padding: 20px;
$page-inner-spacing: 12px;
$input-height: 36px;

// BRANDING COLOR PALETTE COLORS  from 1-14-20 (ONLY TO BE USED ON NAVIGATION ITEMS CURRENTLY for representing branded "solutions" aka Hole Watch, etc.)
$W_DK_BLUE: #0063a7;
$W_LT_BLUE: #4197cb;
$W_GOLD: #f5c851;
$W_ORANGE: #ef823f;
$W_GREEN: #5bbc93;
$W_LAVENDER: #c6d8ff;
$W_PURPLE: #aa71ff;
$W_PINK: #f07da8;
$W_RED: #ff1b1c;
$W_TEAL: #7cffcb;
$W_DK_GRAY: #373a36;
$W_LT_GRAY: #7b868c;
$W_BG_DARK: #231F20;
$W_BG_LT: #d3d0cb;
$W_LT_TEAL: #bffff1;

// Meeting components
$MEETING_SIDEBAR_WIDTH: 380px;

// Console components
$MAIN_NAV_WIDTH: 90px;
$CONSOLE_HEADER_HEIGHT: 45px;
$CONSOLE_SIDEBAR_WIDTH: 280px;
$CONSOLE_SIDEBAR_XXL_WIDTH: 325px;
$DVR_CONTROL_HEIGHT: 115px;
$DVR_CONTROL_SHORT_HEIGHT: 50px;
$MAP_PADDING: 30px;
$MAP_DASHBOARD_PANEL_WIDTH: 400px;

// Additional colors
$WHISPER: #E9E9E9;
$LOCHMARA: #3873ae;
$GRAY_47: #474747;
$CIAN: #02c3c3;
$SNACKBAR_RED: #af0000;
$SNACKBAR_PINK: #FFCFCF;
$DEFAULT_RED: #FF0000;
$DEFAULT_GREEN: #008000;
$DEFAULT_GRAY: #808080;
$DEFAULT_YELLOW: #FFFF00;
$GRAY_INACTIVE: #212121;
$GRAY_PROGRESS_BAR_CONTAINER: #eee;
$GRAY_SELECTED_ITEM: #929292;
$AVATAR_ICON: #888888;
$AVATAR_BACKGROUND: #EEEEEE;

// Breakpoints
$sm: 567px;
$med: 768px;
$lg: 992px;
$xl: 992px;